<template>
	<div id="app">
		
	  	<div>
			<el-button type="primary" @click="open()" >上传</el-button>
			<el-button type="primary" @click="search()" >搜索</el-button>
			<el-input v-model="inputparam" placeholder="请输入车型名称或车辆型号" style="width: 30%;margin-left: 0px;"></el-input>
			<i class="el-icon-close" @click="searchtextclear()"></i>
			<span style="color:orangered;margin-left: 30px">重传后文件会在24小时内刷新,非立即刷新,首次上传请谨慎操作! </span>
			<el-popover placement="top" width="160" v-model="visible">
				<el-row>修改密码:<el-input v-model="inputpassword" placeholder="请输入密码" style="width: 100%;"></el-input></el-row>
				<div style="text-align: right; margin: 0">
    				<el-button size="mini" type="text" @click="visible = false">取消</el-button>
    				<el-button type="primary" size="mini" @click="updatedzsmpwdsubmit()">确定</el-button>
				</div>
				<el-tag type="success" style="position:absolute;right:75px; font-size: 23px;" slot="reference">{{this.companyname}}</el-tag>
			</el-popover>
			<!-- <el-tag type="success" style="position:absolute;right:75px; font-size: 23px;" @click="updatedzsmpwd()">{{this.companyname}}</el-tag> -->
			<i  style="position:absolute;right:5px;size: 55px;color:darkgray" @click="toLogin()">退出登录</i>
	  	</div>
	  	<el-table
	  		:data="datalist.slice((currentPage-1)*pagesize,currentPage*pagesize)"
	  		:current-page.sync="currentPage"
	  		style="width: 100%;text-align: center;"
	  		:stripe="stripe">
	  		<el-table-column
				fixed
				prop="ProName"
				label="车型名称"
				align: center>
	  		</el-table-column>
	  		<el-table-column
				prop="model"
				label="车辆型号"
				align: center>
	  		</el-table-column>
	  		<el-table-column
				prop="genre"
				label="车辆类别"
				align: center>
	  		</el-table-column>
	  		<el-table-column
				label="网页浏览链接(非打印码,禁止复制)"
				width="350px">
				<template slot-scope="scope">
					<el-link type="primary" @click="urlpage(scope.row.url)" class="boxText">{{scope.row.url}}</el-link>
				</template>
	  		</el-table-column>
	  		<el-table-column
	  			prop="uploaddate"
				label="上传时间"
				align: center>
	  		</el-table-column>
	  		<el-table-column
				fixed="right"
				label="操作"
				width="270px">
				<template slot-scope="scope">
					<el-button size="mini" @click.native.prevent="pdfurlcopy(scope.$index, datalist,pagesize,currentPage)" type="info">复制链接</el-button>
					<el-button slot="reference" size="mini" @click.native.prevent="modifyparameters(scope.$index, datalist,pagesize,currentPage)" type="primary">修改</el-button>
					<el-button size="mini" type="danger" @click.native.prevent="modifyupfile(scope.$index, datalist,pagesize,currentPage)">重传</el-button>
				</template>
	  		</el-table-column>
		</el-table>
		<div class="pagination" >
			<el-pagination
        		@size-change="handleSizeChange"
            	@current-change="handleCurrentChange"
            	:page-sizes="[12, 24, 36]"
				:pager-count="11"
            	:page-size="pagesize"
            	layout="sizes, prev, pager, next, jumper"
            	:total=this.datalist.length>
    		</el-pagination>
			<el-dialog title="上传" :visible.sync="dialogOfUpload" width="35%" style="text-align: center;">
		  		<el-row>*车型名称:<el-input v-model="inputname" placeholder="请输入内容" style="width: 70%;"></el-input></el-row>
		  		<el-row>*车辆型号:<el-input v-model="inputcode" placeholder="请输入内容" style="width: 70%;margin-top: 10px;"></el-input></el-row>
		  		<el-row>*车辆类别:<el-select v-model="inputtype" placeholder="请选择类别" style="width:70%;margin-top: 10px;">
    				<el-option v-for="item in typelist" :key="item.LOOKUP_CODE" :label="item.LOOKUP_CODE" :value="item.LOOKUP_CODE"></el-option></el-select>
					<i class="el-icon-plus" style="margin-left: 10px;color:cornflowerblue;"  @click="drawer = true"></i>
				</el-row>
				<el-upload ref="uploadRef" class="upload-demo" action="#" drag :limit="1" :headers="headers" :auto-upload="false"
					:file-list="fileList" :on-change="handleChange" >
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">上传pdf格式文件</div>
				</el-upload>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogOfUpload = false">取 消</el-button>
					<el-button type="primary" @click="confirmUpload()">上 传</el-button>
				</div>
			</el-dialog>
			<el-dialog title="信息修改" :visible.sync="dialogOfmodifyparameters" width="35%" style="text-align: center;">
		  		<el-row>车型名称:<el-input v-model="inputnamemodify" placeholder="请输入内容" style="width: 70%;"></el-input></el-row>
		  		<el-row>车辆型号:<el-input v-model="inputcodemodify" placeholder="请输入内容" style="width: 70%;margin-top: 10px;"></el-input></el-row>
		  		<el-row >车辆类别:<el-select v-model="inputtypemodify" placeholder="请选择类别" style="width: 70%;margin-top: 10px;">
    				<el-option v-for="item in typelist" :key="item.LOOKUP_CODE" :label="item.LOOKUP_CODE" :value="item.LOOKUP_CODE"></el-option></el-select>
				</el-row>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogOfmodifyparameters = false">取 消</el-button>
					<el-button type="primary" @click="modifysubmit()">确定</el-button>
				</div>
			</el-dialog>
			<el-dialog title="重新上传" :visible.sync="dialogOfmodifyupfile" width="35%" style="text-align: center;">
				<el-upload ref="modifyupfileRef" class="upload-demo" action="#" drag :limit="1" :headers="headers" :auto-upload="false"
					:file-list="modifyfileList" :on-change="handleChangemodify" >
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">上传pdf格式文件</div>
				</el-upload>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogOfUpload = false">取 消</el-button>
					<el-button type="primary" @click="modifyupfilesubmit()">上 传</el-button>
				</div>
			</el-dialog>
			<el-dialog title="密码修改" :visible.sync="dialogOfupdatepwd" width="35%" style="text-align: center;">
		  		<el-row>密码:<el-input v-model="inputname" placeholder="请输入内容" style="width: 70%;"></el-input></el-row>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogOfupdatepwd = false">取 消</el-button>
					<el-button type="primary" @click="updatedzsmpwdsubmit()">确定</el-button>
				</div>
			</el-dialog>
			<el-dialog title="上传中,请勿操作" :visible.sync="dialogOfupdatewait" width="35%" style="text-align: center;">
				<i class="el-icon-loading"></i>
			</el-dialog>
		</div>
		<el-drawer
  title="车辆类别"
  :visible.sync="drawer"
  :direction="direction"
  :before-close="handleClose">
  
  <div v-if="drawerinput">
	<span style="margin-bottom:10px">现有类别:</span>
	<el-input v-for="v in typelist" style="margin-left: 5px;margin-right: 5px;width:150px" v-model="v.LOOKUP_CODE" @blur="updateprotype(v.ID,v.LOOKUP_CODE)"></el-input>
  	<el-button type="primary" round @click="drawerinputok">确认</el-button>
  </div>
  <div v-else>
	<span style="margin-bottom:10px">现有类别:</span>
	<el-tag v-for="v in typelist" style="margin-left: 5px;margin-right: 5px;">{{ v.LOOKUP_CODE }}</el-tag>
	<el-button type="primary" round @click="drawerinput = true">修改</el-button>
  </div>
  
  <br/>
  <span style="margin-top: 20px;">添加类别:</span>
  <el-input style="width:300px;margin-left:5px" v-model="typeinput" ></el-input>
  <el-button type="primary"  style="margin-left:10px" @click="insertprotype">新增</el-button>
</el-drawer>
	</div>
</template>
<script>
	import axiosutil from '../utils/axiosutil';
	export default{
		data() {
	  		return {
				marqueeText: ['Hello', 'World', 'Vue'],
      currentIndex: 0,
      intervalId: null,
				typeinput:'',
				drawerinput: false,
				typelist:[],
				drawer: false,
        		direction: 'btt',
				inputnamemodify:'',
				inputcodemodify:'',
				inputtypemodify:'',
				options:[{
						value:"电动摩托车",
						lable:"电动摩托车"
					},{
						value:"电动正三轮轻便摩托车",
						lable:"电动正三轮轻便摩托车"
					},{
						value:"电动自行车",
						lable:"电动自行车"
					},{
						value:"电动轻便摩托车",
						lable:"电动轻便摩托车"
					}],
				dialogOfupdatewait:false,
				visible:false,
				modifyupurl:'',
				dialogOfupdatepwd:false,
				dialogOfmodifyupfile:false,
				modifyurl:'',
				inputparam:'',
				stripe:true,
				currentPage:1,
        		pagesize:12,
				update:'',
				datalist:'',
				username:'',
				password:'',
				companycode:'',
				companyname:'',
				inputname:'',
				inputcode:'',
				inputtype:'',
				inputpassword:'',
				dialogOfmodifyparameters: false,
				dialogOfUpload: false,
				fileList: [],
				modifyfileList: [],
				headers: {
					'Content-Type': 'multipart/form-data'
				}
	  		}
		},
		name: "App",
		mounted() {
    this.startMarquee();
  },
		methods:{
			startMarquee() {
      this.intervalId = setInterval(() => {
        this.currentIndex++;
        if (this.currentIndex >= this.marqueeText.length) {
          this.currentIndex = 0;
        }
      }, 2000);
    },
			insertprotype(){
				var url = '/manual/insertprotype'
				var params = {
					companycode: this.companycode,
					LOOKUP_CODE: this.typeinput
				}
				axiosutil.post(url,params).then(res=>{
					if(res.data == 'completed'){
						this.typeinput = ''
						this.querytypelist()
						this.$message({
					  		message: "成功",
					  		duration: 1000
				  		});
					}else if(res.data == 'repeat'){
						this.$message({
					  		message: "重复",
					  		duration: 1000
				  		});
					}else{
						this.$message({
					  		message: "网络异常",
					  		duration: 1000
				  		});
					}
				})
			},
			drawerinputok(){
				this.querytypelist()
				this.drawerinput = false;
			},
			updateprotype(ID,LOOKUP_CODE){
				var url = '/manual/updateprotype'
				var params ={
					companycode: this.companycode,
					LOOKUP_CODE: LOOKUP_CODE,
					ID: ID
				}
				axiosutil.post(url,params).then(res=>{})
			},
			querytypelist(){
				var url = '/manual/querytypelist'
				var params ={
					companycode: this.companycode
				}
				axiosutil.post(url,params).then(res=>{
					this.typelist = res.data
				})
			},
			handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
			updatedzsmpwdsubmit(){
				this.visible= false;
				var success = "success";
				var error = "error";
				var nocompany = "nocompany";
				var param = new FormData();
				param.append("password",this.inputpassword);
				param.append("username",this.username);
				param.append("companycode",this.companycode);
				axiosutil.post("/manual/updatedzsmpwd",param).then(res=>{
					console.log(res);
					if(res.data==success){
						this.$message({
					  		message: "修改成功！",
					  		duration: 1000
				  		});
						this.dialogOfupdatepwd = false;
					}else if(res.data==error){
						this.$message({
					  		message: "修改失败！",
					  		duration: 1000
				  		});
					}else if(res.data==nocompany){
						this.$message({
					  		message: "公司归属错误！",
					  		duration: 1000
				  		});
					}else{
						this.$message({
					  		message: "网络异常！",
					  		duration: 1000
				  		});
					}
				});
			},
			addprotype(){
				console.log('1')
			},
			updatedzsmpwd(){
				this.dialogOfupdatepwd = true;
			},
			toLogin(){
				this.$router.push({name:'login'})
			},
			modifyupfilesubmit(){
				this.dialogOfupdatewait = true;
				var url = this.modifyupurl;
				var a = "/";
				var k = this.getIndex(url,a,3);
				console.log(url.substring(k,url.length));
				var filename = url.substring(k,url.length);
				var param = new FormData();
				this.modifyfileList.forEach((val,index)=>{
					param.append("file",val.raw);
					param.append("filename",filename);
				});
				axiosutil.post("/manual/retransmission",param).then(res=>{
					this.dialogOfupdatewait = false;
					if(res.data.uploadres=="success"){            
						this.$message({
					  		message: "重传成功！",
					  		duration: 1000
				  		});
						this.$refs.modifyupfileRef.clearFiles();
						this.dialogOfmodifyupfile=false;
		  			}else if(res.data.uploadres=="error"){
						this.$message({
					  		message: "上传错误！",
					  		duration: 1000
				  		});
		  			}else if(res.data.uploadres=="exception"){
						this.$message({
					  		message: "上传异常！",
					  		duration: 1000
				  		});
		  			}else if(res.data.uploadres=="noupload"){
						this.$message({
					  		message: "旧文件清除失败未上传！",
					  		duration: 1000
				  		});
		  			}else if(res.data.deleteres=="nullfilename"){
						this.$message({
					  		message: "未获取到文件名！",
					  		duration: 1000
				  		});
		  			}else if(res.data.uploadres=="nullfile"){
						this.$message({
					  		message: "空文件！",
					  		duration: 1000
				  		});
		  			}else{
						this.$message({
					  		message: "传输失败！",
					  		duration: 1000
				  		});
		  			}
				})
			},
			modifyupfile(id,datalist,pagesize,currentPage){
				var s = ((currentPage-1)*pagesize)+id;
				this.modifyupurl = datalist[s].url;
				this.dialogOfmodifyupfile=true;
			},
			modifysubmit(){
				var param = new FormData();
				param.append("name",this.inputnamemodify);
				param.append("code",this.inputcodemodify);
				param.append("type",this.inputtypemodify);
				param.append("url",this.modifyurl)
				param.append("companycode",this.companycode);
				axiosutil.post("/manual/updateparams",param).then(res=>{
					this.dialogOfmodifyparameters=false;
					this.datalist="";
					axiosutil.get("/manual/query?companycode="+this.companycode).then(res =>{console.log(res.data);this.datalist=res.data;});
					this.inputnamemodify="";
					this.inputcodemodify="";
					this.inputtypemodify="";
					this.modifyurl="";
				});
			},
			modifyparameters(id,datalist,pagesize,currentPage){
				var s = ((currentPage-1)*pagesize)+id;
				this.dialogOfmodifyparameters=true;
				this.inputnamemodify=datalist[s].ProName;
				this.inputcodemodify=datalist[s].model;
				this.inputtypemodify=datalist[s].genre;
				this.modifyurl=datalist[s].url;
			},
			searchtextclear(){
				this.inputparam="";
			},
			handleSizeChange(val) {
            	this.pagesize=val;
        	},
        	handleCurrentChange(val) {
            	this.currentPage = val;
        	},
			search(){
				this.currentPage=1;
				this.datalist="";
				axiosutil.get("/manual/conditionalquery?param="+this.inputparam+"&companycode="+this.companycode).then(res =>{console.log(res.data);this.datalist=res.data;});
			},
			urlpage(param){
				window.open(param,"_blank");
			},
			pdfurlcopy(id,datalist,pagesize,currentPage){
				var s = ((currentPage-1)*pagesize)+id;
				var url = datalist[s].url;
				var a = "/";
				var k = this.getIndex(url,a,3);
				var scene="https://mall.qifeisoft.com/dzsm?scene="+url.substring(k,(url.length)-4);
				this.copyWx(scene);
	  		},
	  		copyWx(item) {
				console.log(item);
        		// 模拟 输入框
      			var cInput = document.createElement("input");
      			cInput.value = item;
      			document.body.appendChild(cInput);
      			cInput.select(); // 选取文本框内容
        		// 执行浏览器复制命令
        		// 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
        		// Input要在正常的编辑状态下原生复制方法才会生效
      			document.execCommand("copy");
      			this.$message({
        			type: "success",
        			message: "复制成功",
					duration: 1000
      			});
        		// 复制成功后再将构造的标签 移除
      			document.body.removeChild(cInput);
    		},
	  		getIndex(str,strpram,index){
      			var strValue=str;
      			var strValue1="";
      			var value=0;
      			for(var i =0;i < index;i++){
        			strValue1 = strValue.substring(0,strValue.indexOf(strpram));
        			strValue = strValue.substring(strValue1.length+1);
        			value += (strValue1.length+1);
      			}
      			return value;
    		},
	  		open(){
				this.dialogOfUpload=true
	  		},
	  		handleChange(file, fileList) { //文件数量改变
				this.fileList = fileList;
		  	},
		  	handleChangemodify(file,modifyfileList){
				this.modifyfileList = modifyfileList;
		  	},
			confirmUpload() { //确认上传
				this.dialogOfupdatewait = true;
				var param = new FormData();
				this.fileList.forEach((val, index) => {
					param.append("file", val.raw);
		  			param.append("name",this.inputname);
		  			param.append("code",this.inputcode);
		  			param.append("type",this.inputtype);
		  			param.append("companycode",this.companycode);
			  	});
				  axiosutil.post("/manual/upload", param).then(responce => {
					this.dialogOfupdatewait=false;
		  			if(responce.data=="success"){            
						this.$message({
					  		message: "上传成功！",
					  		duration: 1000
				  		});
						this.$refs.uploadRef.clearFiles();
						this.dialogOfUpload=false;
						this.datalist="";
						axiosutil.get("/manual/query?companycode="+this.companycode).then(res =>{console.log(res.data);this.datalist=res.data;});
		  			}else if(responce.data=="error"){
						this.$message({
					  		message: "上传错误！",
					  		duration: 1000
				  		});
		  			}else if(responce.data=="exception"){
						this.$message({
					  		message: "上传异常！",
					  		duration: 1000
				  		});
		  			}else if(responce.data=="empty"){
						this.$message({
					  		message: "上传空！",
					  		duration: 1000
				  		});
		  			}else{
						this.$message({
					  		message: "上传失败！",
					  		duration: 1000
				  		});
		  			}
				});
				this.inputname = '';
				this.inputcode = '';
				this.inputtype = '';
			},
		},
		computed: {
    currentText() {
      return this.marqueeText[this.currentIndex];
    }
  },
		created(query) {  
			this.companycode = this.$route.query.companycode;
			this.companyname = this.$route.query.companyname;
			this.username = this.$route.query.username;
			this.password = this.$route.query.password;
			axiosutil.get("/manual/query?companycode="+this.$route.query.companycode).then(res =>{console.log(res.data);this.datalist=res.data;});
			this.querytypelist();
		},
	};
</script>
<style lang="scss">
.popoverclass{
	background-color:bisque;
	width: 30px;
}
.boxText {
	-ms-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.marquee {
  width: 200px;
  height: 20px;
  overflow: hidden;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}
</style>