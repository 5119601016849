import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/Login.vue'
import UploadView from '../views/Upload.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/upload',
    name: 'upload',
    component: UploadView
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: '/dzsmweb',
  routes
})

export default router
