<template>
  <div  style = "display: flex; flex-direction: column; justify-content: center; align-items: center;">
    <div class="loginbox">
      <h5 class="loginTitle">綮霂测试上传系统</h5>
      <el-input v-model="username" placeholder="用户名" style="margin-top: 6px;"></el-input>
      <el-input v-model="password" @keyup.enter.native="submitLogin()" placeholder="密码" style="margin-top: 6px;" show-password></el-input>
      <el-button type="primary" @click="submitLogin()" style="margin-top: 6px;width:100%;">登录</el-button>
    </div>
    <div class="bottom-link">
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color:gray">备案号:</a>
        <a href="https://beian.miit.gov.cn/" target="_blank" style="color:gray">冀ICP备2022013757号-1</a>
    </div>
  </div>
</template>
<script>
  import axiosutil from '../utils/axiosutil';
  export default{
    data() {
      return {
        username:"",
        password:"",
        companyname:"empty",
        companycode:""
      }
    },
    name: "App",
    methods:{
      submitLogin(){
        var param = new FormData();
		    param.append("username",this.username);
		    param.append("password",this.password);
		    // param.append("companycode",this.companycode);
        axiosutil.post("/manual/login", param).then(res => {
          console.log(res);
          var pwderror = "pwderror";
          var success = "success";
          var usernameerror = "usernameerror"; 
          this.Codecorrespondingname(this.username);
          this.companycode = this.username;
          if(success==res.data){
            this.$message({
					    message: "登录成功",
					    duration: 1000
				    });
            this.$router.push({name:'upload',query: {companycode:this.companycode,companyname:this.companyname,username:this.username,password:this.password}})
          }else{
            if(res.data==pwderror){
              this.$message({
					      message: "密码错误",
					      duration: 1000
				      });
            }else if(res.data==usernameerror){
              this.$message({
					      message: "账号错误",
					      duration: 1000
				      });
            }else{
              this.$message({
					      message: "网络异常",
					      duration: 1000
				      });
            }
          }
        })
      },
      Codecorrespondingname(username){
        if(username=="cj"){
          this.companyname = "畅捷";
        }else if(username=="dy"){
          this.companyname = "大阳";
        }else if(username=="gy"){
          this.companyname = "冠宇";
        }else if(username=="hm"){
          this.companyname = "黑马";
        }else if(username=="lj"){
          this.companyname = "铃杰";
        }else if(username=="qf"){
          this.companyname = "企飞";
        }else if(username=="sg"){
          this.companyname = "赛鸽";
        }else if(username=="st2l"){
          this.companyname = "三同两轮";
        }else if(username=="st"){
          this.companyname = "三同三轮";
        }else if(username=="xt"){
          this.companyname = "锡特";
        }else if(username=="xc"){
          this.companyname = "星畅";
        }else if(username=="zbx"){
          this.companyname = "智博星";
        }else if(username=="ys"){
          this.companyname = "优速";
        }else if(username=="gl"){
          this.companyname = "国乐";
        }else if(username == "tl"){
          this.companyname = "台铃";
        }else if(username == "amd"){
          this.companyname = "奥玛达";
        }else if(username == "jc"){
          this.companyname = "捷畅";
        }else{
          this.companyname = "empty";
        }
      }
      // change(code,name){
      //   this.companycode=code;
      //   this.companyname=name;
      // },
    },
    created() { 
    },
  };
</script>
<style>
/* .el-dropdown-link {
  font-size: 18px;
  cursor: pointer;
  color: #409EFF;
} */
/* .el-icon-arrow-down {
  font-size: 12px;
} */
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.loginTitle{
  margin: 0px auto 48px auto;
  text-align: center;
  font-size: 30px;
}
.loginbox{
  width: 300px;
  height: 300px;
  position: absolute;
  left: 45%;
  top: 40%;
  margin: -50px 0 0 -50px;
}
body{
  /* background-image: url("../assets/background.png") ; */
  background-size:100%;
}

.bottom-link {  

position: fixed;  

bottom: 0;  

left: 0;  

right: 0;  

margin: 0 auto; /* 水平居中 */  

text-align: center; /* 垂直居中 */  

padding: 10px 20px; /* 可根据需要调整内边距 */  

}
</style>